const Helpers = () => {
  return (
    <div className="container mt-3 mb-5">
      <img
        src="kitchen-conversion-chart.jpg"
        alt="Kitchen Conversion Chart"
        style={{ maxWidth: '100%' }}
      />
    </div>
  );
};

export default Helpers;
