import { useContext, useState, useEffect } from 'react';
import { DataContext } from './DataProvider';

function ReleaseNotes() {
  const { colorPref } = useContext(DataContext);
  const [releaseData, setReleaseData] = useState([]);

  useEffect(() => {
    const fetchAndParseCSV = async () => {
      try {
        const response = await fetch('changeHistory.csv');
        const text = await response.text();
        setReleaseData(parseCSV(text));
      } catch (error) {
        console.error('Error fetching or parsing CSV:', error);
        setReleaseData([]);
      }
    };

    const parseCSV = (csvText) => {
      const lines = csvText.split('\n');
      const headers = lines[0].split(',');

      // Remove BOM character if present
      if (headers[0].charCodeAt(0) === 0xfeff) {
        headers[0] = headers[0].slice(1);
      }

      const data = [];
      for (let i = 1; i < lines.length; i++) {
        // don't include commas inside double quotes
        const line = lines[i].replace(/"(.*?)"/g, (match, p1) =>
          p1.replace(/,/g, '~~')
        );
        const currentLine = line.split(',');

        // Verify the line has the correct number of elements.
        if (currentLine.length === headers.length) {
          const entry = {};
          for (let j = 0; j < headers.length; j++) {
            let header = headers[j].trim();
            let value = currentLine[j].replace(/~~/g, ',').trim(); // put the commas back

            if (value.startsWith('"') && value.endsWith('"')) {
              value = value.slice(1, -1);
            }
            entry[header] = value;
          }
          data.push(entry);
        }
      }
      return data;
    };

    fetchAndParseCSV();
  }, []);

  return (
    <div className="mt-4 mb-5 container-fluid">
      <div className={'fs-1 text-' + colorPref}>Release Notes </div>
      {releaseData.length === 0 ? (
        <p>No changes found.</p>
      ) : (
        releaseData.map((entry, index) => (
          <div className="mt-4" key={index}>
            <p className={'py-1 px-2 my-1 rounded bg-' + colorPref + '-subtle'}>
              <strong>
                Version {entry.Version}
                {' (' +
                  new Date(entry.Date).toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  }) +
                  ')'}
              </strong>
            </p>
            <strong className="ps-2">{entry.Description}</strong>
            <ul>
              {(entry.Changes ? entry.Changes.split(';') : []).map(
                (change, i) => (
                  <li key={i}>{change.trim()}</li>
                )
              )}
            </ul>
          </div>
        ))
      )}
    </div>
  );
}

export default ReleaseNotes;
