import { Chart } from 'react-google-charts';
import { useContext, useState, useEffect } from 'react';
import { DataContext } from './DataProvider';

const ItemHistoryChart = () => {
  const { history } = useContext(DataContext);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const itemHistory = history.filter(
      (event) => event.event_type === 'shopping'
    );

    const itemCounts = itemHistory.reduce((counts, event) => {
      const itemName = event.event_data.item;
      // counts[itemName] = (counts[itemName] || 0) + event.event_data.qty;
      counts[itemName] = (counts[itemName] || 0) + 1;
      return counts;
    }, {});

    const sortedItems = Object.entries(itemCounts)
      .sort(([, countA], [, countB]) => countB - countA)
      .slice(0, 49); // show only the 49 most popular items. 7 pages

    // Prepare data for Google Charts Table
    const chartData = [['Item', 'Count']];
    sortedItems.forEach(([item, count]) => chartData.push([item, count]));

    const options = {
      showRowNumber: true,
      allowHtml: true,
      width: '100%',
      alternatingRowStyle: false,
      sort: 'disable',
      page: 'enable',
      pageSize: 7,
      pagingButtons: 'auto',
    };

    setChartData({ data: chartData, options: options });
  }, [history]);

  if (!chartData) {
    return <div>Loading chart...</div>;
  }

  const formatters = [
    {
      type: 'BarFormat',
      column: 1,
      options: {
        width: 100,
      },
    },
  ];

  return (
    <div>
      <div className="fs-3 mb-2 text-center">Favorite Items</div>
      <Chart
        chartType="Table"
        data={chartData.data}
        options={chartData.options}
        formatters={formatters}
        chartVersion="51" // workaround for: Attempting to load version '51'... ERROR
      />
    </div>
  );
};

export default ItemHistoryChart;
