import { Chart } from 'react-google-charts';
import { useContext, useState, useEffect } from 'react';
import { DataContext } from './DataProvider';

const MealHistoryChart = () => {
  const { history } = useContext(DataContext);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const mealHistory = history.filter(
      (event) =>
        event.event_type === 'meal' && !event.event_data.name.startsWith('lo:')
    );

    const mealCounts = mealHistory.reduce((counts, event) => {
      const mealName = event.event_data.name;
      counts[mealName] = (counts[mealName] || 0) + 1;
      return counts;
    }, {});

    const sortedMeals = Object.entries(mealCounts)
      .sort(([, countA], [, countB]) => countB - countA)
      .slice(0, 21); // show only the 21 most popular meals

    // Prepare data for Google Charts Table
    const chartData = [['Meal', 'Count']];
    sortedMeals.forEach(([meal, count]) => chartData.push([meal, count]));

    const options = {
      showRowNumber: true,
      allowHtml: true,
      width: '100%',
      alternatingRowStyle: false,
      sort: 'disable',
      page: 'enable',
      pageSize: 7,
      pagingButtons: 'auto',
    };

    setChartData({ data: chartData, options: options });
  }, [history]);

  if (!chartData) {
    return <div>Loading chart...</div>;
  }

  const formatters = [
    {
      type: 'BarFormat',
      column: 1,
      options: {
        width: 100,
      },
    },
  ];

  return (
    <div>
      <div className="fs-3 mb-2 text-center">Favorite Meals</div>
      <Chart
        chartType="Table"
        data={chartData.data}
        options={chartData.options}
        formatters={formatters}
        chartVersion="51" // workaround for: Attempting to load version '51'... ERROR
      />
    </div>
  );
};

export default MealHistoryChart;
