import { Table, Input } from 'antd';
import { useContext, useState, useEffect } from 'react';
import { DataContext } from './DataProvider';
import { Alert, Button } from 'react-bootstrap';
import { ArrowClockwise, InfoCircle } from 'react-bootstrap-icons';
import MealHistoryChart from './MealHistoryChart';
import ShoppingHistoryChart from './ShoppingHistoryChart';

const History = ({ showWelcomeMsg }) => {
  const { colorPref, updateUserPreffs, history, loadHistory } =
    useContext(DataContext);

  const [filter, setFilter] = useState('');
  const [mealShopFilter, setMealShopFilter] = useState(false);
  const [showHistWelcomeMsg, setShowHistWelcomeMessage] = useState(false);

  useEffect(() => {
    if (showWelcomeMsg) {
      setShowHistWelcomeMessage(true);
    }
  }, [showWelcomeMsg]);

  const ackWelcomeMsg = () => {
    if (showHistWelcomeMsg) {
      updateUserPreffs({
        seenHistWelcomeMessage: true,
      });
    }
    setShowHistWelcomeMessage(false);
  };

  const tableColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => new Date(b.date) - new Date(a.date),
    },
    // {
    //   title: 'Type',
    //   dataIndex: 'type',
    // },
    {
      title: 'Description',
      dataIndex: 'desc',
      // sortDirections: ['ascend', 'descend', 'ascend'],
      // sorter: (a, b) => {
      //   // Extract the string value from the Desc component
      //   const aDesc = a.desc.props.children;
      //   const bDesc = b.desc.props.children;
      //   return aDesc.localeCompare(bDesc);
      // },
    },
  ];

  const tableData = history?.map((event, index) => {
    const firebaseTimestamp = new Date(
      event.timestamp.seconds * 1000 + event.timestamp.nanoseconds / 1000000
    );
    // const fb_date = firebaseTimestamp.toDateString();
    // const fb_time = firebaseTimestamp.toLocaleTimeString();
    // const timestamp = fb_date + ' ' + fb_time;

    const desc = () => {
      if (event.event_type === 'shopping') {
        const qty =
          event.event_data.qty > 1 ? '(' + event.event_data.qty + ')' : '';
        return `${event.event_data.item} ${qty}`;
      } else if (event.event_type === 'meal') {
        const sides =
          event.event_data.sides.length > 1 && event.event_data.sides[0] !== ''
            ? ' w/ ' + event.event_data.sides.join(', ')
            : '';
        return event.event_data.name + sides;
      } else if (event.event_type === 'create_account') {
        return event.event_data.message;
      } else {
        return 'N/A';
      }
    };

    const date = () => {
      if (event.event_type === 'shopping' || event.event_type === 'meal') {
        return event.event_data.date;
      } else {
        return firebaseTimestamp.toLocaleDateString();
      }
    };

    return {
      key: index,
      type: event.event_type,
      desc: desc(),
      date: date(),
    };
  });

  const filteredData = tableData.filter((event) => {
    if (mealShopFilter) {
      // Filter by both description and type
      return (
        event.desc.toLowerCase().includes(filter.toLowerCase()) &&
        event.type === mealShopFilter // mealShopFilter will store "meal" or "shopping"
      );
    } else {
      // Filter only by description
      return event.desc.toLowerCase().includes(filter.toLowerCase());
    }
  });

  return (
    <div className="mt-4 mb-5 container-fluid">
      {showHistWelcomeMsg && (
        <Alert
          className="small"
          variant={colorPref}
          onClose={ackWelcomeMsg}
          dismissible
        >
          <Alert.Heading>
            <strong className="me-auto">Welcome to History</strong>
          </Alert.Heading>
          Your personalized meal and shopping history. Here you can explore your
          past week's menus and track your grocery purchases.
          <br />
          <br />
          Every time you advance your menu or check off an item on your shopping
          list, we'll save it here for you. Use this information to understand
          your eating habits, discover favorite meals, and streamline your
          grocery shopping.
        </Alert>
      )}
      <div className="row">
        <div className={'col-5 fs-1 text-' + colorPref}>
          History{' '}
          <sup>
            <InfoCircle
              size={12}
              className="pointer"
              onClick={() => setShowHistWelcomeMessage(true)}
            />
          </sup>
        </div>
        {tableData.length > 0 && (
          <div className="col mt-2 ml-auto text-end">
            <Button
              size="sm"
              className="pb-2"
              variant={colorPref}
              onClick={loadHistory}
            >
              <ArrowClockwise size={18} />
            </Button>
          </div>
        )}
      </div>
      <div className={'my-2 row text-center text-' + colorPref}>
        <div className="col-6 pl-2">
          <Input
            placeholder="Filter by description"
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <div className="col-6 py-0 px-2 text-end">
          <input
            type="radio"
            className="btn-check"
            name="filter-options"
            id="option0"
            autoComplete="off"
            checked={!mealShopFilter} // Checked if mealShopFilter is set to false
            onChange={() => setMealShopFilter(false)} // Set to false when clicked
          />
          <label className="btn btn-sm" htmlFor="option0">
            All
          </label>
          <input
            type="radio"
            className="btn-check"
            name="filter-options"
            id="option1"
            autoComplete="off"
            checked={mealShopFilter === 'meal'} // Checked if mealShopFilter is "meal"
            onChange={() => setMealShopFilter('meal')} // Set to "meal" when clicked
          />
          <label className="btn btn-sm" htmlFor="option1">
            Meals
          </label>
          <input
            type="radio"
            className="btn-check"
            name="filter-options"
            id="option2"
            autoComplete="off"
            checked={mealShopFilter === 'shopping'} // Checked if mealShopFilter is "shopping"
            onChange={() => setMealShopFilter('shopping')} // Set to "shopping" when clicked
          />
          <label className="btn btn-sm" htmlFor="option2">
            Shopping
          </label>
        </div>
      </div>

      {tableData.length >= 49 && mealShopFilter === 'meal' && (
        <>
          <MealHistoryChart />
          <div className="fs-3 my-2 text-center">
            Meal History ({filteredData.length} meals)
          </div>
        </>
      )}

      {tableData.length > 0 &&
        tableData.length < 49 &&
        mealShopFilter === 'meal' && (
          <>
            <div className="small my-3 text-center">
              Keep eating! 😋 Add about {50 - filteredData.length} more meals to
              your history and we'll list your favorites here...
            </div>
            <div className="fs-3 mb-2 text-center">
              Meal History ({filteredData.length} meals)
            </div>
          </>
        )}

      {tableData.length >= 99 && mealShopFilter === 'shopping' && (
        <>
          <ShoppingHistoryChart />
          <div className="fs-3 my-2 text-center">
            Shopping History ({filteredData.length} items)
          </div>
        </>
      )}

      {tableData.length > 0 &&
        tableData.length < 99 &&
        mealShopFilter === 'shopping' && (
          <>
            <div className="small my-3 text-center">
              Keep shopping! 🤑 Add about {100 - filteredData.length} more items
              to your history and we'll list your favorites here...
            </div>
            <div className="fs-3 mb-2 text-center">
              Shopping History ({filteredData.length} items)
            </div>
          </>
        )}

      <Table
        columns={tableColumns}
        dataSource={filteredData}
        pagination={false}
        showSorterTooltip={false}
        rowKey={(r) => r.key}
        // onRow={(r) => {
        //   return {
        //     onClick: () => handleSelectItem(r.item),
        //   };
        // }}
      />
      {tableData.length === 0 && (
        <div className="col mt-2">
          <Button
            // size="sm"
            className="mt-2 w-100"
            variant={colorPref}
            onClick={loadHistory}
          >
            <ArrowClockwise size={18} /> Load History
          </Button>
        </div>
      )}
    </div>
  );
};

export default History;
