const Product = () => {
  const handleLoginClick = () => {
    window.location.href = '/login';
  };

  return (
    <div className="text-muted container mt-4 mb-5 col-md-7">
      <div className="text-center">
        <h2>Say Goodbye to Dinnertime Dilemmas with Meal Buddy</h2>
        <p>
          Tired of the "What's for dinner?" scramble? Meal Buddy is the fun,
          easy-to-use app that takes the stress out of meal planning, grocery
          shopping, and putting delicious food on the table.
        </p>
        <p>
          <img
            style={{
              width: '100%',
              maxWidth: '600px',
              border: '1px solid #ccc',
              borderRadius: '10px',
              // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
            }}
            src="happy_couple_1.jpg"
            alt="Happy Couple"
          />
        </p>
      </div>
      <h3 className="text-center my-4">
        Here's how Meal Buddy simplifies your life:
      </h3>
      <ul>
        <li className="mb-3">
          <strong className="fs-5">Effortless Meal Planning</strong>
          <br />
          No more dinnertime drama. Plan a week's worth of meals in minutes.
          Save recipes and create custom meal plans that fit your schedule and
          taste buds.
        </li>
        <li className="mb-3">
          <strong className="fs-5">One-Tap Shopping Lists</strong>
          <br />
          Generate a complete shopping list based on your meal plan with a
          single tap. Organize your list by store and aisle number so you can
          navigate the grocery store like a pro and never forget an item again.
        </li>
        <li className="mb-3">
          <strong className="fs-5">Smart Recipe Management</strong>
          <br />
          Add your own recipes or import them from the web in seconds using the
          magic of AI. Meal Buddy automatically extracts ingredients and
          instructions, making recipe management a breeze.
        </li>
        <li className="mb-3">
          <strong className="fs-5">Family Collaboration</strong>
          <br />
          Share your Meal Buddy account with your partner or family members and
          conquer mealtime together.
        </li>
        <li>
          <strong className="fs-5">From Groceries to Everything Else</strong>
          <br />
          Want more than just food? Packie run before the game? Department store
          essentials? Need shampoo AND salsa? Meal Buddy handles it all. It even
          lets you organize by aisle so you're not wandering around the store
          like a lost tourist.
        </li>
      </ul>
      <h3 className="text-center my-4">Meal Buddy is perfect for:</h3>
      <ul>
        <li className="mb-3">
          <strong className="fs-5">Families 👶</strong>
          <br />
          Simplify dinnertime and reclaim precious family time.
        </li>
        <li className="mb-3">
          <strong className="fs-5">Couples 😍🥰</strong>
          <br />
          Collaborate on meal ideas, share shopping lists, and make mealtime a
          better experience.
        </li>
        <li className="mb-3">
          <strong className="fs-5">Organized Individuals 😎</strong>
          <br />
          Enjoy a streamlined, stress-free approach to meal planning and grocery
          shopping.
        </li>
        <li className="mb-3">
          <strong className="fs-5">Nerds Rejoice 🤓 (We See You)</strong>
          <br />
          Love lists? Obsessed with efficiency? This app has more options than a
          restaurant menu. You'll be in organizational heaven.
        </li>
        <li>
          <strong className="fs-5">Anyone who eats 😋</strong>
          <br />
          Seriously, whether you're a seasoned chef or just starting out, Meal
          Buddy makes life easier (and tastier).
        </li>
      </ul>
      <h5 className="text-center my-4">
        Ready to banish dinnertime stress and rediscover the joy of cooking?
      </h5>
      <button
        className="btn btn-primary w-100"
        type="button"
        onClick={handleLoginClick}
      >
        Log in today and get started!
      </button>
      <h3 className="text-center my-4">More features you'll love:</h3>
      <ul>
        <li className="mb-3">
          <strong className="fs-5">Customizable Aisle Descriptions</strong>
          <br />
          Organize your grocery lists by store and add custom aisle descriptions
          for ultimate efficiency.
        </li>
        <li className="mb-3">
          <strong className="fs-5">Recipe Notes</strong>
          <br />
          Jot down important cooking instructions, substitutions, or tips for
          next time.
        </li>
        <li>
          <strong className="fs-5">Fun and Intuitive Design</strong>
          <br />
          Because meal planning should be enjoyable, not a chore.
        </li>
      </ul>
      <hr />
      <div className="text-center mt-3">
        <p>"Not bad."</p>
        <p>
          — Christine M, <em>Grocery Shopper & My Sweetie</em> 😘
        </p>
      </div>
      <hr />
      <p>
        <strong>
          Don't wait! Start planning smarter, shopping faster, and eating better
          with Meal Buddy. Log in to the app today.
        </strong>
      </p>
    </div>
  );
};

export default Product;
