import { LifePreserver } from 'react-bootstrap-icons';
// import { Link } from 'react-router-dom';

const Support = () => {
  return (
    <div className="text-muted container mt-4 mb-5 col-md-7">
      <h2>
        Help & Support <LifePreserver size={48} style={{ float: 'right' }} />
      </h2>
      <p>
        We get it, even the most organized chefs need a little help sometimes.
        Whether you're having trouble adding a recipe or just want to learn
        about Meal Buddy's awesome features, we've got you covered!
      </p>
      <h5>Here are some ways to find the answers you need:</h5>
      <ul>
        <li>
          <strong>Browse our FAQs (coming soon): </strong>
          {/* <Link to="/faqs">Click here for FAQs</Link>  */}
          We've compiled answers to the most common questions about Meal Buddy.
          From account setup to troubleshooting tips, it's a great place to
          start.
        </li>
        <li>
          <strong>Get in Touch:</strong> Still can't find what you're looking
          for? Our friendly support team is here to help! Send us a message at{' '}
          <strong>support@meal-buddy.com</strong> and we'll get back to you as
          soon as possible.
        </li>
      </ul>

      {/* <h3>Popular Help Topics:</h3>
      <ul>
        <li>
          <a href="[Link]">Getting Started with Meal Buddy</a>
        </li>
        <li>
          <a href="[Link]">Creating and Managing Shopping Lists</a>
        </li>
        <li>
          <a href="[Link]">Importing Recipes from the Web</a>
        </li>
        <li>
          <a href="[Link]">Sharing Your Account with Family & Friends</a>
        </li>
        <li>
          <a href="[Link]">Troubleshooting Tips & Tricks</a>
        </li>
      </ul> */}

      <h5>Meal Buddy Tips & Tricks:</h5>
      <ul>
        <li>
          <strong>Did you know...?</strong> You can organize shopping lists by
          store type. Create lists for holiday shopping at the mall, lists for
          your favorite department, hardware, or home store. Plus many more.
        </li>
        <li>
          <strong>Pro Tip:</strong> Use Meals to manage lists of grocery items
          to make adding to the shopping list much easier. For example, create a
          meal named "Game Snacks" to group all the fixins and notes for your
          famous game-day feast in one place. Then just select this meal using
          the "Add Meal Items" selector on the Shopping page.
        </li>
      </ul>

      <p>
        We're always here to help you make the most of Meal Buddy. Happy
        cooking!
      </p>
    </div>
  );
};

export default Support;
