import { Tabs, Switch } from 'antd';
import { useContext, useState, useEffect } from 'react';
import { DataContext } from './DataProvider';
import ListManager from './ListManager';
import Alert from 'react-bootstrap/Alert';
import { InfoCircle, List } from 'react-bootstrap-icons';

const Shop = ({ showWelcomeMsg }) => {
  const { storeTypes, userPreffs, updateUserPreffs, colorPref } =
    useContext(DataContext);
  const [shoppingMode, setShoppingMode] = useState(false);
  const [activeList, setActiveList] = useState(
    localStorage.getItem('activeList') || storeTypes[0]
  );
  const [showShoppingModeMessage, setShowShoppingModeMessage] = useState(false);
  const [showShopWelcomeMsg, setShowShopWelcomeMessage] = useState(false);
  let wakeLock = null;

  useEffect(() => {
    const requestWakeLock = async () => {
      try {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        wakeLock = await navigator.wakeLock.request('screen');
        localStorage.setItem('wakeLockStatus', 'acquired');
        // console.log('Wake lock acquired:', wakeLock);
      } catch (err) {
        console.error('Failed to acquire wake lock:', err);
        localStorage.setItem('wakeLockError', err);
        localStorage.setItem('wakeLockStatus', 'error');
      }
    };

    // Check if Wake Lock API is supported. requires SSL connection
    if ('wakeLock' in navigator) {
      if (shoppingMode) {
        requestWakeLock();
      } else if (wakeLock) {
        // Release wake lock when not shopping
        wakeLock.release();
        wakeLock = null;
        localStorage.removeItem('wakeLockError');
        localStorage.removeItem('wakeLockStatus');
        // console.log('Wake lock released');
      }
    } else {
      // Log that the API is not supported, no need to add an Alert
      // console.log('Wake Lock API not supported');
      localStorage.setItem('wakeLockError', 'Wake Lock API not supported');
    }

    return () => {
      // cleanup: release wake lock on component unmount if it exists
      if (wakeLock) {
        wakeLock.release();
        wakeLock = null;
        localStorage.removeItem('wakeLockStatus');
        // console.log('Wake lock released (cleanup)');
      }
      localStorage.removeItem('wakeLockError');
    };
  }, [shoppingMode]);

  useEffect(() => {
    if (showWelcomeMsg) {
      setShowShopWelcomeMessage(true);
    }
  }, [showWelcomeMsg]);

  const ackWelcomeMsg = () => {
    if (showWelcomeMsg) {
      updateUserPreffs({
        seenShopWelcomeMessage: true,
      });
    }
    setShowShopWelcomeMessage(false);
  };

  const tabNames = storeTypes.map((type) => {
    return {
      label: type,
      key: type,
      children: <ListManager listType={type} shoppingMode={shoppingMode} />,
    };
  });

  const toggleShoppingMode = (on) => {
    let navbarTop = document.getElementById('navbar-top');
    let navbarBottom = document.getElementById('navbar-bottom');
    let navbarShop = document.getElementById('navbar-shop');
    let appContainer = document.getElementById('app-container');
    let clearListButton = document.getElementById('clear-list-button');

    if (on) {
      if (!userPreffs?.seenShoppingModeMessage) {
        setShowShoppingModeMessage(true);
        updateUserPreffs({
          seenShoppingModeMessage: true,
        });
      }
      setShoppingMode(true); // This triggers the useEffect to request wake lock
      navbarTop.style.display = 'none';
      navbarBottom.style.display = 'none';
      navbarShop.style.display = 'block';
      appContainer.style.paddingTop = '1.7rem';
      appContainer.style.paddingBottom = '0';
      clearListButton.style.display = 'none';
    } else {
      setShoppingMode(false); // This triggers the useEffect to release wake lock
      navbarTop.style.display = 'block';
      navbarBottom.style.display = 'block';
      navbarShop.style.display = 'none';
      appContainer.style.paddingTop = '3rem';
      appContainer.style.paddingBottom = '3rem';
      clearListButton.style.display = 'block';
    }
  };

  const shopSwitch = (
    <span>
      &nbsp;
      <InfoCircle
        size={12}
        className={'pointer text-' + colorPref}
        onClick={() =>
          shoppingMode
            ? setShowShoppingModeMessage(true)
            : setShowShopWelcomeMessage(true)
        }
      />
      &nbsp; &nbsp;
      <Switch
        checkedChildren="Edit"
        unCheckedChildren="Shop"
        onChange={(e) => {
          toggleShoppingMode(e);
        }}
      />
    </span>
  );

  const setActiveListPref = (list) => {
    localStorage.setItem('activeList', list);
    setActiveList(list);
  };

  return (
    <div className="pt-1 container-fluid">
      {showShopWelcomeMsg && (
        <Alert
          variant={colorPref}
          onClose={ackWelcomeMsg}
          className="small mt-4"
          dismissible
        >
          <Alert.Heading>
            <strong className="me-auto">Welcome to Shopping Lists</strong>
          </Alert.Heading>
          A single place for organized shopping. Create custom lists for
          groceries, household goods, hardware, liquor, pharmacy needs, and
          more.
          <br />
          <br />
          Add individual items, entire meals, or even pre-set menus to your
          grocery and wholesale lists for ultimate convenience. Easily manage
          your lists by editing quantities, renaming items, and even assigning
          them to your stores.
          <br />
          <br />
          Ready to hit the aisles? Flip the switch to activate shopping mode and
          watch as items disappear from your list with a tap, seamlessly
          transitioning to your shopping history for future reference.
        </Alert>
      )}
      {showShoppingModeMessage && (
        <Alert
          variant={colorPref}
          className="small mt-2"
          onClose={() => setShowShoppingModeMessage(false)}
          dismissible
        >
          <Alert.Heading>
            <strong className="me-auto">Welcome to Shopping Mode</strong>
          </Alert.Heading>
          While in Shopping Mode, <b>tapped items will be </b>
          <b className="text-danger px-1">AUTOMATICALLY REMOVED</b> from your
          list and added to your shopping history.
          <br />
          <br />
          You can view your shopping and meal history from the Options <List />{' '}
          menu.
        </Alert>
      )}

      {'wakelockStatus: ' + localStorage.getItem('wakeLockStatus')}
      <br />
      {'wakelockError: ' + localStorage.getItem('wakeLockError')}

      <Tabs
        id="shop-tabs"
        tabBarExtraContent={shopSwitch}
        items={tabNames}
        activeKey={activeList}
        onTabClick={setActiveListPref}
      />
    </div>
  );
};

export default Shop;
